define('init',['edap'], function(edap) {
    'use strict';

    // EDAP guarantees that edap.globalOptions is always an {}
    var edapOpts = edap.globalOptions,
        skipPageview = edapOpts.skipPageview;

    function init() {
        // Add integrations version to EDAP
        edap.integrationsVersion = '9.0.1';

        if (edapOpts.skipFlush !== true) {
            edap.trigger('edap.flush');
        }

        if (skipPageview !== true) {
            edap.trigger('pageview');
        }

        if (edapOpts.skipTagManager !== true) {
            // if skipPageview option is set to true, trigger this on the manual pageview
            // to guarantee the data layer is set before the tag manager loads
            if (skipPageview === true) {
                edap.once('pageview', function() {
                    edap.trigger('tagmanager.load');
                });
            } else {
                edap.trigger('tagmanager.load');
            }
        }

        if (edapOpts.skipFeatureFlags === true) {
            // We want to call features.load() immediately so that any features.onLoad()
            // callbacks are executed just with no FeatureFlags set.
            edap['public'].features.load([]);
        } else {
            edap.trigger('proctor.load');
        }

        return edap;
    }

    return {
        init: init
    };
});

