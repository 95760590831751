define('tagmanager/tmshelpers',[
    'domhelpers'
], function(domHelpers) {
    'use strict';

    var ensightenTestName = 'edapFeatureLoadEnsighten',
        spacePlaceHolder = '{SPACE}',
        ensightenTempateUrl = '//nexus.ensighten.com/away/' + spacePlaceHolder + '/Bootstrap.js',
        envSpaceMappings = {
            bnb: {
                'unit': 'bnb-test',
                'dev': 'bnb-test',
                'test': 'bnb-test',
                'stage': 'bnb-stage',
                'production': 'bnb-production'
            },
            stayz: {
                'unit': 'stayz-test',
                'dev': 'stayz-test',
                'test': 'stayz-test',
                'stage': 'stayz-stage',
                'production': 'stayz-production'
            },
            toprural: {
                'unit': 'toprural-test',
                'dev': 'toprural-test',
                'test': 'toprural-test',
                'stage': 'toprural-stage',
                'production': 'toprural-production'
            },
            haem: {
                'unit': 'haem-test',
                'dev': 'haem-test',
                'test': 'haem-test',
                'stage': 'haem-stage',
                'production': 'haem-production'
            },
            'default': {
                'unit': 'test',
                'dev': 'test',
                'test': 'test',
                'stage': 'stage',
                'production': 'production'
            }
        },
        haem = 'haem',
        monikerToSpaceMappings = {
            bnb: 'bnb',
            stayz: 'stayz',
            toprural: 'toprural',
            homeaway_in: haem,
            homeaway_ar: haem,
            homeaway_co: haem,
            homeaway_ru: haem,
            homeaway_cn: haem,
            homeaway_hk: haem,
            homeaway_id: haem,
            homeaway_jp: haem,
            homeaway_kr: haem,
            homeaway_my: haem,
            homeaway_ph: haem,
            homeaway_sg: haem,
            homeaway_th: haem,
            homeaway_tw: haem,
            homeaway_vn: haem,
            homeaway_ec: haem,
            homeaway_uy: haem,
            homeaway_pe: haem,
            homeaway_cl: haem,
            homeaway_asia: haem,
            'default': 'default'
        },
        myEdap;

    return {
        /**
         * Simple getter for tagmanager EDAP Scope
         *
         * @return {Object} tagmanager EDAP scope
         */
        getEdapScope: function() {
            return myEdap;
        },

        /**
         * Simple setter for tagmanager EDAP Scope
         *
         * @param  {Object} edapInstance New value for tagmanager EDAP scope
         */
        setEdapScope: function(edapInstance) {
            myEdap = edapInstance;
        },

        /**
         * Initialize tag manager tools
         *
         * @param  {Object} edapInstance The tagmanager integration's EDAP Scope
         */
        init: function(edapInstance) {
            var that = this;

            that.setEdapScope(edapInstance);

            that.initEnsighten();
        },

        /**
         * Sets up necessary edap on() handler for loading the appropriate bootstrapper
         */
        initEnsighten: function() {
            var that = this;

            myEdap.once('tagmanager.load', function(data) {
                myEdap['public'].features.onLoad(function() {
                    var ensightenEnabled = myEdap['public'].features.getFeatureProperty(ensightenTestName, 'value') === 'on';

                    if (ensightenEnabled) {
                        that.loadEnsighten(data.monikerbrand, data.appenvironment);
                    }
                });
            });
        },

        /**
         * Loads the appropriate Ensighten bootstrapper
         *
         * @param  {String} monikerbrand   The monikerbrand to load the bootstrapper for
         * @param  {String} appenvironment The app environment to load a bootstrapper for
         */
        loadEnsighten: function(monikerbrand, appenvironment) {
            var that = this,
                bootstrapperUrl = that.getBootstrapperUrl(monikerbrand, appenvironment),
                currentTimestamp = domHelpers.getMsFromEpoch();

            // getBootstrapperUrl is guaranteed to return a valid URL
            domHelpers.loadScript(bootstrapperUrl);

            // store current timestamp into scope data so we can check how long loading took
            myEdap.getData().loadtimestamp = currentTimestamp;
        },


        /**
         * Gets the appropriate bootstrapper URL for the given app environment
         * Will always return a valid URL - if appenvironment is invalid, defaults to 'production'
         *
         * @param  {String} monikerbrand   The monikerbrand to load the bootstrapper for
         * @param  {String} appenvironment The app environment to retrieve a bootstrapper URL for
         * @return {String}                The URL for appenvironment
         */
        getBootstrapperUrl: function(monikerbrand, appenvironment) {
            var brandLookup = 'default',
                envSpaceName;

            // If the monikerbrand starts with bnb then it is one of the bnb brands
            if (monikerbrand && (monikerbrand.indexOf('bnb') === 0)) {
                brandLookup = 'bnb';
            } else if (monikerToSpaceMappings.hasOwnProperty(monikerbrand)) {
                brandLookup = monikerbrand;
            }


            envSpaceName = envSpaceMappings[monikerToSpaceMappings[brandLookup]][appenvironment];

            // Figure out which Bootstrapper is appropriate
            if (envSpaceName === undefined) {
                // default to production URL
                envSpaceName = envSpaceMappings[monikerToSpaceMappings[brandLookup]].production;
            }

            return ensightenTempateUrl.replace(spacePlaceHolder, envSpaceName);
        }
    };
});

