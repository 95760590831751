define('proctor/edap-proctor-integration',['domhelpers', 'edap', 'proctor/proctor'], function(domHelpers, edap, Proctor) {
    'use strict';

    var moduleName = 'proctor',
        myEdap = new edap.Scope(moduleName);

    /**
     * Sets up a listener to tell EDAP when to grab the proctor bucketing info
     *
     * @returns {Object} - edap
     */
    function init() {
        myEdap.once('proctor.load', function(data, setAsync, done) {
            var proctor = myEdap.getData().proctor,
                edapOpts = myEdap.globalOptions, // EDAP guarantees that globalOptions is always an {}
                startLoad = domHelpers.getMsFromEpoch(),
                replayExecutionTime = null,
                hasLoadedFeatures = false,
                endLoad,
                startReplay;

            // This function is called when loading the Proctor data is done, one way or another
            function listenerComplete() {
                // If we haven't loaded the features yet then we do that now to make sure any onLoad()
                // callbacks are called
                if (!hasLoadedFeatures) {
                    myEdap['public'].features.load([]);
                }

                // trigger our "did.load" event for proctor
                myEdap.trigger('proctor.did.load', {
                    loadtime: '' + (endLoad - startLoad),
                    replaytime: '' + replayExecutionTime,
                    loadstatus: proctor.data.status
                }).then(done);
            }

            setAsync();

            if (!(proctor instanceof Proctor)) {
                proctor = new Proctor(myEdap, {
                    endpointOrigin: edapOpts.endpointOrigin
                });
                myEdap.getData().proctor = proctor;
            }

            proctor.loadProctorData().then(function() {
                var hasScripts = proctor.data.scripts.length > 0,
                    hasFeatures = proctor.data.featureFlagList.length > 0;

                endLoad = domHelpers.getMsFromEpoch();

                if (proctor.data.status === proctor.state.LOADED) {
                    if (hasScripts) {
                        domHelpers.loadScriptMulti(proctor.data.scripts);

                        if (!hasFeatures) {
                            listenerComplete();
                        }
                    }

                    if (hasFeatures) {
                        myEdap['public'].features.load(proctor.data.featureFlagList);
                        hasLoadedFeatures = true;

                        startReplay = domHelpers.getMsFromEpoch();

                        myEdap['public'].playback.replay().then(function() {
                            replayExecutionTime = domHelpers.getMsFromEpoch() - startReplay;
                            listenerComplete();
                        });
                    }

                    if (!hasFeatures && !hasScripts) {
                        listenerComplete();
                    }
                } else {
                    listenerComplete();
                }
            });
        });

        return edap;
    }

    return {
        init: init
    };
});

