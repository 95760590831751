define('proctor/proctor',['domhelpers', 'doneable', 'edapUnderscore', 'xhrhelpers'], function(domHelpers, Doneable, _, XHRHelper) {
    'use strict';

    /**
     * Proctor model constructor.
     * Contains all the business logic for communicating with and parsing responses from edap-service.
     *
     * @constructor
     * @param {Object} [edap] - window.edap or an EDAP scope to be used internally
     * @param {Object} [config] - configuration options supported:
     *                           - {String} [uuidCookieName] - Cookie name for the EDAP UUID cookie
     *                           - {String} [prForceGroupsCookieName] - Cookie name to store forced groups
     *                           - {String} [endpointOrigin] - Origin of the edap-service endpoint, (origin automatically added if blank)
     *                           - {String} [endpointPath] - Path to the edap-service endpoint
     *                           - {Integer} [uuidCookieTimout] - Max lifetime of the EDAP uuid cookie
     *                           - {Integer} [ajaxTimeout] - Max time, in ms, to wait for the AJAX response
     */
    function Proctor(edap, config) {
        var proctor = this,
            initErr;

        /**
         * Validate
         */

        if (!edap || !edap.hasOwnProperty('edapVersion')) {
            throw new TypeError('Proctor: must pass in EDAP when creating a new instance');
        }

        if (!(proctor instanceof Proctor)) {
            initErr = new TypeError('Proctor must be called with the "new" keyword');
            edap.error(initErr);
            throw initErr;
        }

        /**
         * Configuration Options
         */

        config = config || {};

        // Default config values
        proctor.config = _.defaults({}, config, {
            uuidCookieName: 'edapVisitorUuid',
            prForceGroupsCookieName: 'edapPrforceGroups',
            endpointOrigin: '',
            endpointPath: '/edap/service/v1/getInitData',
            uuidCookieTimout: 2 * 365 * 24 * 60, // 2 years
            ajaxTimeout: 3 * 1000 // 3 s
        });

        // Set this to a local property in case a consumer of EDAP-Integrations doesn't
        // have access to the Proctor model
        proctor.state = Proctor.state;

        // Init our data container when we load the data
        proctor.data = {
            featureFlagList: [],
            scripts: [],
            status: Proctor.state.INIT
        };

        /**
         * Gets the EDAP Visitor UUID if set, creates it if not
         *
         * @private
         * @return {String} EDAP's visitor UUID for this user
         */
        proctor.getEdapVisitorUUID = function() {
            var edapVisitorUuid = domHelpers.getCookie(proctor.config.uuidCookieName);

            if (!edapVisitorUuid) {
                edapVisitorUuid = edap['public'].createUuid();
                domHelpers.setCookie(
                    proctor.config.uuidCookieName,
                    edapVisitorUuid,
                    domHelpers.getHostname(),
                    proctor.config.uuidCookieTimout
                );
            }

            return edapVisitorUuid;
        };


        /**
         * Gets the EDAP proctor buckets a user has forced themselves into
         *
         * @private
         * @return {String} The prforceGroups String (e.g. "edapFeatureEnableHadoop0,edapRumPayloadTst1")
         */
        proctor.getForcedProctorGroups = function() {
            // current mechanism is storing in "edapPrforceGroups" cookie
            // if we decide we want an API for this (e.g. edap.internal, window.edapOpts, etc) we could do that too

            return domHelpers.getCookie(proctor.config.prForceGroupsCookieName) || '';
        };


        /**
         * Parses the response object from the edap-service "responseText" string
         *
         * @private
         * @params {String} [responseText] - edap-service XHR "responseText" string
         * @return {Object} - edap-service response object, or an empty object if there's a parse error
         */
        proctor.getResponseJSON = function(responseText) {
            try {
                return JSON.parse(responseText);
            } catch (e) {
                edap.error('getResponseJSON(): Could not parse JSON responseText: ' + responseText);
            }

            return {};
        };


        /**
         * Gets the list of async scripts to load from the edap-service return value
         *
         * @private
         * @params {Object} [responseJSON] - JSON response from edap-service
         * @return {Array} - The scripts list returned by edap-service, or an empty Array
         */
        proctor.getScriptsList = function(responseJSON) {
            if (responseJSON.scripts instanceof Array) {
                return responseJSON.scripts;
            }

            return [];
        };


        /**
         * Parses the payload of a feature
         *
         * @private
         * @params {Object} [payload] - The payload string form a feature
         * @return {*} - The JSON.parse() value of payload, if it was a JSON.stringify()'d object
         *               If it doesn't JSON.parse() then it returns the passed in parameter
         */
        proctor.parsePayload = function(payload) {
            var parsePayload = payload;

            if (typeof payload === 'string') {
                try {
                    parsePayload = JSON.parse(payload);
                } catch (e) {
                    parsePayload = payload;
                }
            }

            return parsePayload;
        };


        /**
         * Parses the list of features and builds a list of FeatureFlag models
         *
         * @private
         * @params {Object} [responseJSON] - JSON response object from edap-service
         * @return {Array} - The list of FeatureFlag models populated with the edap-service features data
         */
        proctor.getFeatureFlagList = function(responseJSON) {
            var ffList = [],
                ff;

            if (typeof responseJSON.features !== 'object') {
                return ffList;
            }

            _.forOwn(responseJSON.features, function(data, key) {
                try {
                    ff = new edap['public'].FeatureFlag(edap, {
                        name: key,
                        value: data.bucketName,
                        payload: proctor.parsePayload(data.payload)
                    });
                    ffList.push(ff);
                } catch (e) {
                    edap.error('getFeatureFlagList(): Could not parse entry ' + key + ': ' + JSON.stringify(data));
                }
            });

            return ffList;
        };


        /**
         * Executes the edap-service XHR and parses the return values.
         * All parsed data is stored in proctor.data.*. Currently the following properties are set:
         *  - {Array} [proctor.data.featureFlagList] - Contains the list of FeatureFlags models
         *  - {Array} [proctor.data.scripts] - Contains the list async scripts to be loaded
         *
         * @public
         * @returns {Doneable} - Doneable that is resolved when the network request is complete. You need to check
         *                       proctor.data.status. It can be set to:
         *                        - Proctor.state.INIT - Default value that means loadProctorData() has not been called
         *                        - Proctor.state.PENDING - XHR request has been sent but no response has been parsed.
         *                        - Proctor.state.LOADED - XHR response received, validated and parsed, proctor.data.* is ready
         *                        - Proctor.state.BADRESPONSE - XHR response received but did not pass validation, proctor.data.* not set
         *                        - Proctor.state.ERROR - XHR threw an error, proctor.data.* not set
         *                        - Proctor.state.TIMEOUT - XHR timed out, proctor.data.* not set
         */
        proctor.loadProctorData = function() {
            return new Doneable(function(done) {
                var logName = 'loadProctorData',
                    deviceType = domHelpers.getDeviceType(),
                    edapVisitorUuid = proctor.getEdapVisitorUUID(),
                    forcedGroups = proctor.getForcedProctorGroups(),
                    url,
                    xhr,
                    err;

                // onload function for xhr object
                function onload(xhrHelper) {
                    var status = xhrHelper.get('status'),
                        responseJSON;

                    if (status === 200) {
                        responseJSON = proctor.getResponseJSON(xhrHelper.get('responseText'));

                        // Now get parse, build and store the data
                        proctor.data.featureFlagList = proctor.getFeatureFlagList(responseJSON);
                        proctor.data.scripts = proctor.getScriptsList(responseJSON);
                        proctor.data.status = Proctor.state.LOADED;
                    } else {
                        err = new Error([
                            logName + '():',
                            'got status',
                            status,
                            'on requesting',
                            url
                        ].join(' '));
                        edap.error(err);

                        proctor.data.status = Proctor.state.BADRESPONSE;
                    }

                    done();
                }

                function onerror() {
                    err = new Error([
                        logName + '():',
                        'network error attempting to reach',
                        url
                    ].join(' '));

                    edap.error(err);

                    proctor.data.status = Proctor.state.ERROR;

                    done();
                }

                function ontimeout() {
                    err = new Error([
                        logName + '():',
                        'network request timed out attempting to reach',
                        url
                    ].join(' '));

                    edap.error(err);

                    proctor.data.status = Proctor.state.TIMEOUT;

                    done();
                }

                url = encodeURI([
                    proctor.config.endpointOrigin,
                    proctor.config.endpointPath,
                    '?deviceType=' + deviceType,
                    '&uuid=' + edapVisitorUuid,
                    '&forcedGroups=' + forcedGroups
                ].join(''));

                xhr = new XHRHelper();

                xhr.createXHR('GET', url, onload, onerror, ontimeout);
                xhr.set('timeout', proctor.ajaxTimeout);

                // Reset the data containers since we are requesting new data
                proctor.data.featureFlagList = [];
                proctor.data.scripts = [];
                proctor.data.status = Proctor.state.PENDING;

                xhr.send();
            }, {
                error: edap.error
            });
        };

        return proctor;
    }

    // Static ENUM of states of the Proctor model
    Proctor.state = {
        INIT: 'init',
        PENDING: 'pending',
        LOADED: 'loaded',
        BADRESPONSE: 'bad response',
        ERROR: 'error',
        TIMEOUT: 'timeout'
    };

    return Proctor;
});

