define('domhelpers',['edap'], function(edap) {
    'use strict';

    var helpers = {

        /**
         *  Getter for window
         *
         *  @returns {Object} - Returns the window object
         */
        getWindow: function() {
            return window;
        },

        /**
         *  Getter for window.screen
         *
         *  @returns {Object} - Returns the window.screen object
         */
        getScreen: function() {
            return this.getWindow().screen || {};
        },

        /**
         * Getter for the current edap-integrations version
         *
         * @return {String} The edap-integrations version or '-1'
         */
        getEdapIntegrationsVersion: function() {
            return edap.integrationsVersion || '-1';
        },


        /**
         *  Getter for window.document
         *
         *  @returns {Object} - Returns the window.document object
         */
        getDocument: function() {
            return document;
        },


        /**
         *  Getter for window.document.referrer
         *
         *  @returns {Object} - Returns the window.document.referrer
         */
        getDocumentReferrer: function() {
            return this.getDocument().referrer;
        },


        /**
         * Helper function to determine if the referrer matches the current domain
         *
         * @return {Boolean} `true` if there is a referrer set AND that referrer does not match the current domain, `false otherwise`
         */
        isCrossDomainReferral: function() {
            var referrer = this.getDocumentReferrer(),
                currentDomainRegex = new RegExp('^' + this.getLocationOrigin() + '/');

            return !!(referrer && referrer.match(currentDomainRegex) === null);
        },


        /**
         *  Getter for window.document.cookie
         *
         *  @returns {Object} - Returns the window.document.cookie object
         */
        getDocumentCookie: function() {
            return document.cookie;
        },


        /**
         *  Setter for window.document.cookie
         *
         *  @param {String} [cookieStr] - Coorectly formatted cookie string to be added as a cookie
         */
        setDocumentCookie: function(cookieStr) {
            document.cookie = cookieStr;
        },


        /**
         *  Getter for window.document.location
         *
         *  @returns {Object} - Returns the window.document.location object
         */
        getLocation: function() {
            return this.getDocument().location;
        },


        /**
         *  Getter for window.document.location.href
         *
         *  @returns {String} - Returns the window.document.location.href string
         */
        getHref: function() {
            return window.document.location.href;
        },


        /**
         *  Getter for window.document.location.protocol
         *
         *  @returns {Object} - Returns the window.document.location.protocol object
         */
        getLocationProtocol: function() {
            return window.document.location.protocol;
        },


        /**
         *  Getter for window.document.location.hostname
         *
         *  @returns {String} - Returns the window.document.location.hostname string
         */
        getHostname: function() {
            return window.document.location.hostname;
        },


        /**
         *  Getter for window.document.location.pathname
         *
         *  @returns {String} - Returns the window.document.location.pathname string
         */
        getPathname: function() {
            return window.document.location.pathname;
        },


        /**
         *  Getter for window.document.location.search
         *
         *  @returns {String} - Returns the window.document.location.search string
         */
        getSearch: function() {
            return window.document.location.search;
        },


        /**
         * Getter for the path and search strings concatenated together
         *
         * @return {String} - Returns the window.document.location pathname and search concatenated
         */
        getPage: function() {
            return helpers.getPathname() + helpers.getSearch();
        },


        /**
         * Getter for the protocol and hostname of the current page
         * NOTE: This value is being computed instead of using document.location.origin due to IE
         *       compatibility issues
         *
         * @return {String} - Returns the window.document.location.origin-like value
         */
        getLocationOrigin: function() {
            return helpers.getLocationProtocol() + '//' + helpers.getHostname();
        },


        /**
         *  Getter for window.navigator
         *
         *  @returns {Object} - Returns the window.navigator object
         */
        getNavigator: function() {
            return window.navigator;
        },


        /**
         * Adds the specified `callbackFunction` as an event listener for `eventName`
         *
         * @param {String} eventName - The event name to listen for
         * @param {Function} callbackFunction - What to do when the event occurs
         */
        addWindowEventListener: function(eventName, callbackFunction) {
            window.addEventListener(eventName, callbackFunction);
        },


        /**
         * Removes the specified `callbackFunction` as an event listener for `eventName`
         *
         * @param {String} eventName - The event name to remove listener for
         * @param {Function} callbackFunction - Function to remove for `eventName`
         */
        removeWindowEventListener: function(eventName, callbackFunction) {
            window.removeEventListener(eventName, callbackFunction);
        },


        /**
         *  Returns the number of milliseconds since 1 January 1970 00:00:00 UTC (Epoch)
         *
         *  @returns {Number} - Returns the number of milliseconds since Epoch
         */
        getMsFromEpoch: function() {
            if (typeof window.Date.now === 'function') {
                return Date.now();
            }
            return (new Date()).getTime();
        },


        /**
         *  Dynamically injects a script before the first script tag on a page.
         *
         *  @example
         *    loadScript('//foo.com/path/to/script.js', 'my-script-load-id');
         *
         *  @param {String} [url] - a fully qualified or protocol-less url to a script. If it is a
         *                          protocol-less url then it will prefix the pages protocol onto the url.
         *  @param {String} [id] - the script element id to set
         *  @returns {Boolean} - if the script tag was added or not (based on if the id already exists)
         */
        loadScript: function(url, id) {
            var doc = this.getDocument(),
                node = doc.getElementsByTagName('script')[0],
                script = doc.createElement('script');

            if (typeof id === 'string' && id.length > 0) {
                // Bail out if the element already exists on the page
                if (doc.getElementById(id) !== null) {
                    return false;
                }

                script.id = id;
            }

            script.async = true;
            script.type = 'text/javascript';

            // If the url doesn't start with '//' then we will assume it is full qualified. Otherwise
            // we'll prefix the url with the current pages protocol (http: or https:).
            if (url.indexOf('//') === 0) {
                script.src = (this.getLocation().protocol === 'https:' ? 'https:' : 'http:') + url;
            } else {
                script.src = url;
            }
            node.parentNode.insertBefore(script, node);

            return true;
        },


        /**
         *  Dynamically injects a set of scripts onto the page
         *
         *  @example
         *    loadScriptMulti(['//foo.com/path/to/script1.js', '//foo.com/path/to/script2.js', ...]);
         *
         *  @param {Array} [urls] - a list of fully qualified or protocol-less urls to scripts. If it is a
         *                          protocol-less url then it will prefix the pages protocol onto the url.
         *  @returns {Boolean} - true if all the script tags were added or false if not
         */
        loadScriptMulti: function(urls) {
            var allSuccessful = true,
                successful,
                i,
                len;

            if (!(urls instanceof Array)) {
                return allSuccessful;
            }

            for (i = 0, len = urls.length; i < len; i++) {
                successful = helpers.loadScript(urls[i]);
                allSuccessful = allSuccessful && successful;
            }

            return allSuccessful;
        },


        /**
         *  Send an image request
         *
         *  @example
         *    sendBeacon('//foo.com/path/to/img.png');
         *
         *  @param {String} [url] - image url
         *  @param {Function} [cb] - Callback with the url passed as the only parameter
         */
        sendBeacon: function(url, cb) {
            new Image().src = url;
            if (typeof cb === 'function') {
                cb(url);
            }
        },


        /**
         *  Gets the contents of a cookie
         *
         *  @example
         *    getCookie('mycookie');
         *
         *  @param {String} [name] - name of the cookie
         *  @returns {Object} - The String cookie data or null
         */
        getCookie: function(name) {
            var nameEQ = name + '=',
                defaultValue = null,
                ca,
                i,
                c;

            try {
                ca = this.getDocumentCookie().split(';');
                for (i = 0; i < ca.length; i++) {
                    c = ca[i];

                    // Strip off any leading whitespace
                    while (c.charAt(0) === ' ') {
                        c = c.substring(1, c.length);
                    }

                    if (c.indexOf(nameEQ) === 0) {
                        return c.substring(nameEQ.length, c.length);
                    }
                }
            } catch (err) {
                edap.error(err);
            }

            return defaultValue;
        },


        /**
         *  Sets a cookie
         *
         *  @example
         *    // sets a "mycookie" with value "1234" on "homeaway.com" for 2 hours
         *    setCookie('mycookie', '1234', 'homeaway.com', 120);
         *
         *  @param {String} [name] - name of the cookie
         *  @param {String} [value] - value of the cookie
         *  @param {String} [hostname] - domain of the cookie
         *  @param {Number} [minutes] - minutes until cookie expires
         */
        setCookie: function(name, value, hostname, minutes) {
            var expires = '',
                date = new Date(),
                expiresMinutes;

            try {
                expiresMinutes = minutes * 60 * 1000;

                date.setTime(date.getTime() + expiresMinutes);

                if (typeof minutes === 'number') {
                    // If minutes is less than 0 then they are trying to remove a cookie and we set max-age as well.
                    if (expiresMinutes <= 0) {
                        expires = '; max-age=0';
                    }
                    expires += '; expires=' + date.toUTCString();
                }

                this.setDocumentCookie(name + '=' + value + expires + '; path=/; domain=' + hostname + ';');
            } catch (err) {
                edap.error(err);
            }
        },


        /**
         *  Removes a cookie
         *
         *  @example
         *    removeCookie('mycookie', 'homeaway.com');
         *
         *  @example
         *    removeCookie('mycookie');
         *
         *  @param {String} [name] - name of the cookie
         *  @param {String} [hostname] - domain of the cookie
         */
        removeCookie: function(name, optHostname) {
            if (!optHostname) {
                optHostname = this.getHostname();
            }

            // Hard coded to 1 day ago in minutes (1440 minutes)
            this.setCookie(name, '', optHostname, -1440);
        },


        /**
         *  Gets the value of a query string parameter
         *
         *  @example
         *    var param = getSearchParam('k_clickid');
         *
         *  @param {String} [name] - name of the query string parameter
         *  @returns {Object} - The String param value or null
         */
        getSearchParam: function(name) {
            var nameEQ = name + '=',
                searchStr,
                params,
                i,
                p;

            try {
                // strip the initial ? and unencode
                searchStr = this.getSearch().replace('?', '');

                params = searchStr.split('&');

                for (i = 0; i < params.length; i++) {
                    p = decodeURIComponent(params[i]);

                    if (p.indexOf(nameEQ) === 0) {
                        return p.substring(nameEQ.length, p.length);
                    }
                }
            } catch (err) {
                edap.error(err);
            }
            return null;
        },

        /**
         * Gets the width of the screen
         *
         * @returns {Object} - The total width of the screen
         */
        getScreenWidth: function() {
            return this.getScreen().width;
        },

        /**
         * Gets the height of the screen
         *
         * @returns {Object} - The total height of the screen
         */
        getScreenHeight: function() {
            return this.getScreen().height;
        },

        /**
         * Gets the width of the view port to determine the media type
         *
         * @returns {Object} - The inner width of a window's content area
         */
        getViewportWidth: function() {
            return this.getWindow().innerWidth
                || this.getDocument().documentElement.clientWidth
                || this.getDocument().getElementsByTagName('body')[0].clientWidth;
        },

        /**
         * Gets the height of the view port
         *
         * @returns {Object} - The inner height of a window's content area
         */
        getViewportHeight: function() {
            return this.getWindow().innerHeight
                || this.getDocument().documentElement.clientHeight      // IE6 in standards compliant mode
                || this.getDocument().getElementsByTagName('body')[0].clientHeight;     // Older versions of IE
        },

        /**
         *  Gets the media type of the page
         *
         *  Use the width of the browser to determine the 3rd layer targeting parameter for user
         *
         *  @returns {String} - String representing the device type:
         *      desktop - Desktop, width >= 992px
         *      tablet - Tablet, 768px <= width < 992px
         *      mobile - Mobile, width < 768px
         */
        getDeviceType: function() {
            var deviceType = 'desktop',
                x;

            try {
                x = this.getViewportWidth();

                if (typeof x === 'number') {
                    if (x < 768) {
                        deviceType = 'mobile';
                    } else if (x < 992) {
                        deviceType = 'tablet';
                    }
                }
            } catch (e) {
                edap.error(e);
            } finally {
                return deviceType;
            }
        },

        /**
         *  Gets the media query size (mqsize) of the page
         *
         *  Use the width of the browser to determine which mqsize was used for the page
         *
         *  @returns {String} - String representing the media query size:
         *      lg - Large devices, width >= 1200px
         *      md - Medium devices, width >= 992px
         *      sm - Small devices, 768px <= width < 992px
         *      xs - Extra small devices, width < 768px
         *      -1 - Error, no size determined
         */
        getMQSize: function() {
            var mqSize = '-1',
                x;

            try {
                x = this.getViewportWidth();

                if (typeof x === 'number') {
                    if (x < 768) {
                        mqSize = 'xs';
                    } else if (x < 992) {
                        mqSize = 'sm';
                    } else if (x < 1200) {
                        mqSize = 'md';
                    } else {
                        mqSize = 'lg';
                    }
                }
            } catch (e) {
                edap.error(e);
            } finally {
                return mqSize;
            }
        }
    };

    return helpers;
});

