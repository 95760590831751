define('hadoop/hadoop-wrapper',['edap', 'domhelpers', 'doneable'], function(edap, domHelpers, Doneable) {
    'use strict';

    function Hadoop() {
        /**
         *  Constant value for a missing required field (matches api-operations-client-logging)
         */
        this.missingDefaultValue = 'NA';

        /**
         *  Beacon-type to url mapping object
         */
        this.urls = {
            'beacon': '/bizops/elo/beacon',
            'img': '/edap/elo/v1/event/img'
        };

        /**
         *  Gets a user's session id
         *  Due to the lack of a unified web session for unauthenticated users this *may* break...
         *
         *  @example
         *    var sid = getHasId();
         *
         *  @returns {String} - Session ID from the associated brand session cookie or this.missingDefaultValue
         */
        this.getHasId = function() {
            var hadoop = this,
                sid = domHelpers.getCookie('has');

            if (sid === null) {
                sid = hadoop.missingDefaultValue;
            }

            return sid;
        };


        /**
         *  Gets a user's visitor id
         *  Due to the lack of a unified web session for unauthenticated users this *may* break...
         *
         *  @example
         *    var sid = getHavId();
         *
         *  @returns {String} - Visitor ID from the associated brand visitor cookie or this.missingDefaultValue
         */
        this.getHavId = function() {
            var hadoop = this,
                vid = domHelpers.getCookie('hav');

            if (vid === null) {
                vid = hadoop.missingDefaultValue;
            }

            return vid;
        };


        function checkData(urls, urlType, eventName, sessionId, visitorId, payload) {
            if (!urls.hasOwnProperty(urlType)) {
                edap.error('hadoop.buildUrl does not support urlType=' + urlType);
                return false;
            }

            if (typeof eventName !== 'string') {
                edap.error('hadoop.buildUrl requires that "eventName" be a string: ' + eventName);
                return false;
            }

            if (typeof sessionId !== 'string') {
                edap.error('hadoop.buildUrl requires that "sessionId" be a string: ' + sessionId);
                return false;
            }

            if (typeof visitorId !== 'string') {
                edap.error('hadoop.buildUrl requires that "visitorId" be a string: ' + visitorId);
                return false;
            }

            // Payload is optional, but if it is set then it needs to be an object
            if (payload && typeof payload !== 'object') {
                edap.error('hadoop.buildUrl requires that "payload" be an object, if set: ' + sessionId);
                return false;
            }

            return true;
        }


        /**
         *  Builds the entire Hadoop url to "send" via IMG or sendBeacon(). All query parameters will URI encoded.
         *
         *  @example
         *    var beaconUrl = buildUrl('img', 'user.email.entered', null, {listingid: '123.1234.1234'});
         *
         *  @param {String} [urlType] - 'img' or 'beacon': 'img' will use an image to send the data, 'beacon' will use navigator.sendBeacon()
         *  @param {String} [eventName] - The EDAP event name
         *  @param {String} [sessionId] - Session ID to send to Hadoop
         *  @param {String} [visitorId] - Visitor ID to send to Hadoop
         *  @param {Object} [payload] - *optional* Object of data to be JSON.stringify()'d
         *  @param {Object} [optionalParams] - *optional* Object of optional parameter data to be added to the URL
         *  @returns {Object} - url path for the Hadoop endpoint with data encoded in query parameters, or null if there was an error
         */
        this.buildUrl = function(urlType, eventName, sessionId, visitorId, payload, optionalParams) {
            var hadoop = this,
                url = this.urls[urlType],
                win = domHelpers.getWindow(),
                n = null,

                // Maps EDAP data names to URL Query Parameter names
                // [optionalParams key name, URL param name]
                optionalParamMapping = [
                    ['emailaddress', 'emailAddress'],
                    ['firstname', 'firstName'],
                    ['lastname', 'lastName']
                ],
                paramList = [],
                site = hadoop.missingDefaultValue,
                encodedPayloadContent,
                i,
                len,
                key,
                renamedKey;

            // Default to an object just to be safe
            optionalParams = optionalParams || {};

            if (!checkData(this.urls, urlType, eventName, sessionId, visitorId, payload)) {
                return n;
            }

            // 'type' gets prefixed with 'edap:' for easier namespaced querying
            paramList.push('type=' + win.encodeURIComponent('edap:' + eventName));
            paramList.push('sessionId=' + win.encodeURIComponent(sessionId));
            paramList.push('visitorId=' + win.encodeURIComponent(visitorId));

            if (payload && payload.hasOwnProperty('monikerbrand')) {
                site = payload.monikerbrand;
            }

            // Set the site from the payload's monikerbrand, defaults to 'NA'
            // (similar to other missing fields in api-operations-client-logging)
            paramList.push('site=' + win.encodeURIComponent(site));

            for (i = 0, len = optionalParamMapping.length; i < len; i++) {
                key = optionalParamMapping[i][0];
                renamedKey = optionalParamMapping[i][1];

                if (optionalParams.hasOwnProperty(key)) {
                    paramList.push(renamedKey + '=' + win.encodeURIComponent(optionalParams[key]));
                }
            }

            // Add in any optional payload data(already validated to be an object if set)
            if (payload && win.JSON && typeof win.JSON.stringify === 'function') {
                try {
                    encodedPayloadContent = win.encodeURIComponent(win.JSON.stringify(payload));
                } catch (err) {
                    edap.error(err);
                    encodedPayloadContent = win.encodeURIComponent('{"error":"Could not JSON.stringify payload"}');
                }

                // Push it onto the parameter list
                paramList.push('payload=' + encodedPayloadContent);
            }

            paramList.push('_restfully=true');

            url = url + '?' + paramList.join('&');
            return url;
        };


        /**
         *  Determines if the browser has navigator.sendBeacon()
         *
         *  @example
         *    var hasSendBeacon = hasSendBeacon();
         *
         *  @returns {Boolean} - true: if navigator.sendBeacon exists, otherwise false
         */
        this.hasSendBeacon = function() {
            var nav = domHelpers.getNavigator();

            if (typeof nav.sendBeacon === 'function') {
                return true;
            }
            return false;
        };


        /**
         *  "Sends" a Hadoop request via an IMG pixel
         *
         *  @example
         *    var sendDoneable = send('user.email.entered', {listingid: '123.1234.1234'}, null, null);
         *
         *  @param {String} [eventName] - The EDAP event name
         *  @param {Object} [data] - Object of data to be JSON.stringify()'d
         *  @param {String} [sessionId] - *optional* Session ID to send to Hadoop, will be filled in if set to null (ex. ADL.sessionid)
         *  @param {String} [visitorId] - *optional* Visitor ID to send to Hadoop, will be filled in if set to null (ex. ADL.visitorid)
         *  @returns {Doneable} - Then-able object so you can associate callbacks upon completion
         */
        this.send = function(eventName, data, sessionId, visitorId) {
            var that = this,
                optionalParams = {},

                // Data to copy/move from data to optionalParams.
                // If found in data then they will be moved to optionalParams and removed from data
                optionalPIIParamsFromData = ['emailaddress', 'firstname', 'lastname'],

                // Keys that should be removed from the data payload before sending
                // Currently these are the Ensighten 1 tracking objects that don't need to be pushed into Hadoop
                removeFromData = ['gaevents', 'thirdparty', 'criteoevents'];

            return new Doneable(function(done) {
                var url,
                    key,
                    i,
                    len;

                if (!sessionId) {
                    sessionId = that.getHasId();
                }

                if (!visitorId) {
                    visitorId = that.getHavId();
                }

                if (data) {
                    for (i = 0, len = optionalPIIParamsFromData.length; i < len; i++) {
                        key = optionalPIIParamsFromData[i];

                        if (data.hasOwnProperty(key)) {
                            optionalParams[key] = data[key];
                            delete data[key];
                        }
                    }

                    // Now let's remove other keys that don't need to go to Hadoop
                    for (i = 0, len = removeFromData.length; i < len; i++) {
                        key = removeFromData[i];

                        if (data.hasOwnProperty(key)) {
                            delete data[key];
                        }
                    }
                }

                // NOTE: When sendBeacon is supported then we can add it here as a conditonal based on hasSendBeacon()

                url = that.buildUrl('img', eventName, sessionId, visitorId, data, optionalParams);
                domHelpers.sendBeacon(url, done);
            }, {
                error: function(err) {
                    edap.error(err);
                }
            });
        };
    }

    return new Hadoop();
});

